<template>
    <div class="section" key="distanceAccusationSpeed">
        <div class="section-title">
            <div class="author"><img width="50" height="50" src="@/assets/images/rabier.png"></div>
            <h3>Wie schnell waren Sie unterwegs?</h3>
            <p>Bitte prüfen Sie den Bescheid: Wie hoch ist die vorgeworfene Geschwindigkeit</p>
        </div>
        <div class="form-container">
            <div class="form-card">
            <input type="radio" v-model="user.distanceAccusationSpeed" name="radio-4" value="<80km/h" id="question-7-1">
            <label for="question-7-1" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons8-speedometer.svg"></span><span>unter 80 km/h</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.distanceAccusationSpeed" name="radio-4" value="81-100km/h" id="question-7-2">
            <label for="question-7-2" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons8-speedometer.svg"></span><span>81 - 100 km/h</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.distanceAccusationSpeed" name="radio-4" value="101-130km/h" id="question-7-3">
            <label for="question-7-3" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons8-speedometer.svg"></span><span>101 - 130 km/h</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.distanceAccusationSpeed" name="radio-4" value=">131km/h" id="question-7-4">
            <label for="question-7-4" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons8-speedometer.svg"></span><span>über 131 km/h</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.distanceAccusationSpeed" name="radio-4" value="unsicher" id="question-7-5">
            <label for="question-7-5" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons8-speedometer.svg"></span><span>Weiß nicht genau</span></label>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'DistanceAccusationSpeed',
  computed: mapState({
    user: (state) => state.user,
    checked: (state) => state.checked
  }),
  methods: {
    ...mapMutations([
      'incrementChecked'
    ]),
  }
}
</script>